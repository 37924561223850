@import url('https://fonts.googleapis.com/css?family=Montserrat');

body {
    font-family: 'Montserrat';
}

#home {
    background-image: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url('../images/background.jpg');
    background-position: center center;
    background-size: cover;
    height: 100vh;
    padding-bottom: 10vh;
}

#americawestride {
    transition: transform .2s;
}

#americawestride:hover {
    transform: scale(1.025);
}

#service {
    background-image: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url('../images/workshop.jpg');
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: auto;
}

#parts {
    background-image: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url('../images/parts.jpg');
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: auto;
}

.icon {
    height: 30vh;
    object-fit: cover;
}

.offcanvas-body {
    padding: 0 !important;
    margin: 0 !important;
}